<template>
  <div class="contactus">
    <div class="banner_container contactus_banner border_text_box">
      <div class="border_text">Contact Us</div>
      <h1 class="border_text_title">联系我们</h1>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > </span>
      <span class="current">联系我们</span>
    </div>
    <contact-us></contact-us>
  </div>
</template>

<script>
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
import ContactUs from "@/components/ContactUs";
export default {
  name: "contactus",
  components: {
    ContactUs
  },
}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
.contactus {
    .contactus_banner {
        background: url('../assets/imgs/contactus/banner.webp') 50% 50%;
        background-size: cover;
        .border_text_title {
          color: #fff
        }
    }
}
@media screen and (max-width: 1160px){}
@media screen and (max-width: 820px){}
@media screen and (max-width: 768px){}
</style>